import { Link } from "react-router-dom";
import { ReactNode } from "react";

interface FooterLink {
  label: string;
  path: string;
}

interface FooterSocialMedia {
  url: string;
  iconPath: string;
}

interface FooterData {
  logo: string;
  links: FooterLink[][];
  socialMediaLinks: FooterSocialMedia[];
  copyrightText: ReactNode;
}

interface FooterProps {
  footerData: FooterData; // Footer data passed as a prop
}

const Footer = ({ footerData }: FooterProps) => {
  const { logo, links, socialMediaLinks, copyrightText } = footerData;

  return (
    <footer
      id="footer-section"
      className="bg-transparent text-black dark:text-white px-4 py-4 relative z-[1]"
    >
      <div className="container mx-auto">
        <div className="py-2 flex max-lg:flex-col max-lg:gap-6 justify-between items-center relative">
          {/* Logo Section */}
          <div className="lg:absolute top-0 left-0 w-full h-full flex justify-center invert dark:invert-0 items-center -z-0">
            <Link to={"/"} className="flex-shrink-0 flex-fill">
              <img src={logo} className="h-7" alt="LOGO" />
            </Link>
          </div>

          {/* Links Section */}
          <div className="relative z-10 flex max-lg:flex-col max-lg:gap-1 justify-center lg:justify-start lg:gap-5 max-lg:text-xl text-2xl flex-shrink-0 flex-fill">
            {links.map((group, index) => (
              <ul
                key={index}
                className="max-lg:flex max-lg:flex-col max-lg:justify-center max-lg:items-center max-lg:gap-1"
              >
                {group.map((link, idx) => (
                  <li key={idx}>
                    <Link
                      to={link.path}
                      className="hover:text-gray-300 text-lg"
                    >
                      {link.label}
                    </Link>
                  </li>
                ))}
              </ul>
            ))}
          </div>

          {/* Social Media Icons */}
          <div className="flex justify-end gap-5 items-center flex-shrink-0 flex-fill relative z-10">
            {socialMediaLinks.map((social, index) => (
              <a
                key={index}
                href={social.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  className="w-7 h-7 dark:fill-white  hover:fill-gray-300"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  5
                  <path d={social.iconPath} />
                </svg>
              </a>
            ))}
          </div>
        </div>

        {/* Copyright Section */}
        <div className="border-t py-4 text-center  border-[#252836] max-lg:px-8 mx-auto">
          {copyrightText}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
