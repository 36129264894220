import { API } from "../router/apiEndpoints"
import axiosInstance from "./axiosInstance"

export const updateUsersDescription = async (description: string): Promise<void> => {
    try {
        await axiosInstance.post(`${API.HOST}${API.USER.UPDATE_USER_DESCRIPTION}`, {
            description: description
        })
    } catch (error) {
        console.error(`Errror during an attempt to update user's description: `, error);
    }
}

export const updateUserNameAndEmail = async (name: string, email: string): Promise<void> => {
    try {
        await axiosInstance.post(`${API.HOST}${API.USER.UPDATE_USER_NAME_EMAIL}`, {
            name: name,
            email: email
        })
    } catch (error) {
        console.error(`Errror during an attempt to update user's email or name: `, error);
    }
}

export const updateUserPassword = async (input: {
    password: string,
    password_confirmation: string,
    new_password: string,
    new_password_confirmation: string
}): Promise<void> => {
    try {
        await axiosInstance.post(`${API.HOST}${API.USER.UPDATE_USER_PASSWORD}`, input)
    } catch (error) {
        console.error(`Errror during an attempt to update user's password: `, error);
    }
}

export const handleVideoCommentLike = async (comment_id: BigInteger) => {
    try {
        await axiosInstance.post(`${API.HOST}${API.COMMENTS.LIKE}`, {comment_id: comment_id});
    } catch (error) {
        console.error(`Error during an attempt to like a comment: `, error);
    }
}