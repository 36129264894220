import React, { ChangeEvent, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../helpers/axiosInstance';
import { API } from '../../router/apiEndpoints';
import { UserContext } from '../../context/UserContext';

const Login = () => {
  const navigate = useNavigate();
  const {login, logout} = useContext(UserContext);
  const [state, setState] = useState({
    email: '',
    password: '',
    remember: false,
  });
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    axiosInstance.get(`${API.HOST}${API.GET_CSRF_TOKEN}`).then(() => {
      axiosInstance.post(`${API.HOST}${API.AUTH.LOGIN}`, state).then(response => {
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          login();
          navigate('/dashboard/home');
        }
      });
    });

    if (state.remember) {
      localStorage.setItem('email', state.email);
      localStorage.setItem('password', state.password); // Consider hashing for security
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-[#302D2A]">
      <div className="w-screen h-screen bg-cover bg-center fixed top-0 left-0">
        <img
          src="/img/shapes-original.png"
          alt="BG"
          className="w-full h-full object-cover object-center"
        />
      </div>
      <main className="flex-grow relative z-[1] flex justify-center items-center">
        <div className="flex justify-center items-center w-[90%] sm:w-full h-full">
          <div className="w-full sm:w-[410px] bg-black p-12 min-[450px]:px-20 min-[450px]:py-14 flex flex-col rounded-[28px]">
            <img
              src={'/img/wizmeek_logo.png'}
              alt="wizmeek-logo"
              className="w-[240px] sm:w-[250px] h-auto cursor-pointer"
              onClick={() => navigate('/')}
            />
            <p className="text-[24px] text-white font-bold mt-6 sm:mt-8">
              Login
            </p>
            <div className="flex flex-col gap-2 sm:gap-4 mt-4 w-full">
              <div className="flex flex-col gap-2">
                <p className="text-base text-white">Email</p>
                <input
                  type="email"
                  value={state.email}
                  onChange={handleChange}
                  name="email"
                  className="rounded-[7px] text-base p-2"
                  placeholder="username@gmail.com"
                />
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-base text-white">Password</p>
                <input
                  type="password"
                  value={state.password}
                  onChange={handleChange}
                  name="password"
                  className="rounded-[7px] text-base p-2"
                  placeholder="Password"
                />
              </div>
              <div className="flex items-center gap-2 ">
                <input
                  type="checkbox"
                  id="remember"
                  name="remember"
                  checked={state.remember}
                  className="w-3 h-3"
                  onChange={() =>
                    setState({ ...state, remember: !state.remember })
                  }
                />
                <label
                  htmlFor="remember"
                  className="text-[12px] text-white select-none cursor-pointer"
                >
                  Remember me
                </label>
              </div>
              <button className="w-full bg-[#003465] rounded-[7px] p-2 text-white text-[20px] font-bold active:scale-[0.97]"
              onClick={handleSubmit}>
                Sign In
              </button>
              <div className="flex flex-col gap-2">
                <p className="text-[12px] text-white">
                  Forgot Password?{' '}
                  <a
                    className="font-bold cursor-pointer select-none"
                    rel="noopener noreferrer"
                  >
                    Click Here
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Login;
