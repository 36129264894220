const headerNavLinks: any[] = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Login",
    path: "/login",
  },
  {
    title: "About Us",
    path: "/about-us",
  },
  {
    title: "Get in Touch",
    path: "/get-in-touch",
  },
];
export default headerNavLinks;
