import React, { createContext, useState, useEffect } from "react";
import Cookies from "js-cookie";

// Create the context
export const UserContext = createContext();

// Create a provider component
export const UserProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    const cookieValue = Cookies.get("isLoggedIn");
    return cookieValue === "true"; // Cookies are always strings, so compare with 'true'
  });

  // Add user details to the state
  const [userDetails, setUserDetails] = useState(() => {
    const savedDetails = localStorage.getItem("userDetails");
    return savedDetails ? JSON.parse(savedDetails) : { id: 0, name: "", avatar: "", doesNewNoticeExist: false };
  });

  const login = (details = {}) => {
    setIsLoggedIn(true);
    Cookies.set("isLoggedIn", "true", { expires: 86400, sameSite: "lax" });

    // Update user details if provided
    if (details) {
      setUserDetails(details);
      localStorage.setItem("userDetails", JSON.stringify(details));
    }
  };

  const logout = () => {
    setIsLoggedIn(false);
    setUserDetails({ id: 0, name: "", avatar: "", doesNewNoticeExist: false });
    Cookies.remove("isLoggedIn", { sameSite: "lax" });
    localStorage.removeItem("userDetails");
  };

  // Sync userDetails to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("userDetails", JSON.stringify(userDetails));
  }, [userDetails]);

  return (
    <UserContext.Provider value={{ isLoggedIn, userDetails, setUserDetails, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};
