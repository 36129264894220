import React, { useContext } from "react";
import { Provider } from "react-redux";
import { ThemeProvider } from "./context/themeContext";
import store from "./redux/store";
import Router from "./router";
import { UserContext } from "./context/UserContext";
import { setLogoutFunction } from "./helpers/axiosInstance";

function App() {
  const { logout } = useContext(UserContext);

  // Set the logout function once the component mounts
  React.useEffect(() => {
    setLogoutFunction(logout);
  }, [logout]);
  return (
    <Provider store={store}>
      <ThemeProvider>
        <Router />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
