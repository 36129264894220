import axios from "axios";
import { selectHost } from "../router/hostHelper";

// Define the type for the logout function
type LogoutFunction = () => void;

let logoutFn: LogoutFunction | null = null; // Initialize with null

// Function to set the logout function
export const setLogoutFunction = (logout: LogoutFunction) => {
  logoutFn = logout;
};

const axiosInstance = axios.create({
  baseURL: selectHost(),
  withCredentials: true,
  withXSRFToken: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    console.log("Request Interceptor:", config);
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => {
    console.log("Response Interceptor:", response);
    return response;
  },
  (error) => {
    console.error("Response Interceptor Error:", error.response);

    if (error.response?.status === 401 || error.response?.status === 419) {
      console.warn("Unauthorized or session expired, logging out...");
      if (logoutFn) {
        logoutFn(); // Safely call the logout function if it's set
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
