import { API } from "../router/apiEndpoints"
import axiosInstance from "./axiosInstance"

export const storeVideoComment = async (video_id: BigInteger, content: string) => {
    try {
        const payload = {
            youtube_video_id: video_id,
            content: content
        };
        console.log("Payload being sent:", payload);

        const response = await axiosInstance.post(`${API.HOST}${API.COMMENTS.STORE}`, payload);
        return response;
    } catch (error) {
        console.error("An error has occured during an attempt to store a new comment: ", error);
    }
}