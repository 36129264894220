import React, { useState } from "react";

interface GenreOption {
  id: string;
  label: string;
  bgColor: string;
}

const TastePage = () => {
  const [selectedGenres, setSelectedGenres] = useState<Set<string>>(new Set());

  const genres: GenreOption[] = [
    { id: "boomBap", label: "Boom Bap", bgColor: "bg-[#C2B067]" },
    { id: "hipHop", label: "Hip Hop", bgColor: "bg-[#A43636]" },
    { id: "gangstaRap", label: "Gangsta Rap", bgColor: "bg-[#E56116]" },
    { id: "drill", label: "DRILL", bgColor: "bg-[#377E59]" },
    { id: "rnb", label: "R&B", bgColor: "bg-[#4167E3]" },
    { id: "pop", label: "POP", bgColor: "bg-[#62D99C]" },
    { id: "alternative", label: "Alternative", bgColor: "bg-[#62BAFF]" },
    { id: "afrobeat", label: "Afrobeat", bgColor: "bg-[#8E7C6A]" },
    { id: "caribbean", label: "Caribbean", bgColor: "bg-[#200E32]" },
    { id: "asian", label: "Asian", bgColor: "bg-[#956600]" },
    { id: "latin", label: "Latin", bgColor: "bg-[#3D0101]" },
  ];

  const toggleGenre = (genreId: string) => {
    const newSelection = new Set(selectedGenres);
    if (newSelection.has(genreId)) {
      newSelection.delete(genreId);
    } else {
      newSelection.add(genreId);
    }
    setSelectedGenres(newSelection);
  };

  const handleDone = () => {
    if (selectedGenres.size < 3) {
      alert("Please select at least 3 genres");
      return;
    }
    console.log("Selected genres:", Array.from(selectedGenres));
  };

  return (
    <div className="h-[65vh] p-8 flex flex-col">
      <h1 className="text-black dark:text-white text-xl mb-6">
        Choose Your Music Taste - Select At least 3 or more.
      </h1>

      <div className="flex-1">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
          {genres.map((genre) => (
            <button
              key={genre.id}
              onClick={() => toggleGenre(genre.id)}
              className={`${genre.bgColor} rounded-lg py-2 px-6 text-white font-semibold
            flex justify-between items-center transition-all duration-200
            hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50`}
            >
              <span>{genre.label}</span>
              <div className="w-6 h-6 bg-white rounded-full flex items-center justify-center">
                {selectedGenres.has(genre.id) && (
                  <svg
                    className="w-5 h-5"
                    fill="none"
                    stroke="#22C55E"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2.5}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                )}
              </div>
            </button>
          ))}
        </div>
      </div>

      {/* Adjusted margin for the button */}
      <div className="flex justify-center ">
        <button
          onClick={handleDone}
          className="bg-[#003465] text-white px-8 py-2 rounded-md
        hover:bg-blue-800 transition-colors duration-200
        focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Done
        </button>
      </div>
    </div>
  );
};

export default TastePage;
