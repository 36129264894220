import React, { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SignUp = () => {
  const [state, setState] = useState({
    name: '',
    email: '',
    password: '',
  });
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const navigate = useNavigate();

  return (
    <div className="flex flex-col min-h-screen bg-[#302D2A]">
      <div className="w-screen h-screen bg-cover bg-center fixed top-0 left-0">
        <img
          src="/img/shapes-original.png"
          alt="BG"
          className="w-full h-full object-cover object-center"
        />
      </div>
      <main className="flex-grow relative z-[1] flex justify-center items-center">
        <div className="flex justify-center items-center w-[90%] sm:w-full h-full">
          <div className="w-full sm:w-[410px] bg-black p-12 min-[450px]:px-20 min-[450px]:py-14 flex flex-col rounded-[28px]">
            <img
              src={'/img/wizmeek_logo.png'}
              alt="wizmeek-logo"
              className="w-[240px] sm:w-[250px] h-auto cursor-pointer"
              onClick={() => navigate('/')}
            />
            <p className="text-[24px] text-white font-bold mt-6 sm:mt-8">
              Sign Up
            </p>
            <div className="flex flex-col gap-2 sm:gap-4 mt-4 w-full">
              <div className="flex flex-col gap-2">
                <p className="text-base text-white">Full Name</p>
                <input
                  type="name"
                  value={state.name}
                  onChange={handleChange}
                  name="name"
                  className="rounded-[7px] text-base p-2"
                  placeholder="John/Jane Doe"
                />
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-base text-white">Email</p>
                <input
                  type="email"
                  value={state.email}
                  onChange={handleChange}
                  name="email"
                  className="rounded-[7px] text-base p-2"
                  placeholder="username@gmail.com"
                />
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-base text-white">Password</p>
                <input
                  type="password"
                  value={state.password}
                  onChange={handleChange}
                  name="password"
                  className="rounded-[7px] text-base p-2"
                  placeholder="Password"
                />
              </div>

              <p className="text-[12px] text-white text-center">
                By signing up you agree to our
                <br />
                <a className="cursor-pointer text-[#0029FE]">
                  Terms of Service{' '}
                </a>
                and{' '}
                <a className="cursor-pointer text-[#0029FE]">Privacy Policy</a>
              </p>

              <button className="w-full bg-[#003465] rounded-[7px] p-2 text-white text-[20px] font-bold active:scale-[0.97]">
                Sign Up
              </button>
              <p className="text-[12px] text-white">
                Already have an account?{' '}
                <a
                  className="font-bold cursor-pointer select-none"
                  rel="noopener noreferrer"
                  onClick={() => navigate('/login')}
                >
                  Login
                </a>
              </p>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default SignUp;
