import React, { useEffect, useRef, useState } from "react";
import { fetchUserProfileDetailsForEdit } from "../../../helpers/fetcher";
import { updateUserNameAndEmail, updateUserPassword, updateUsersDescription } from "../../../helpers/updater";
import axiosInstance from "../../../helpers/axiosInstance";
import { API } from "../../../router/apiEndpoints";

const Settings = () => {

  const [profileData, setProfileData] = useState({
    avatar: "",
    name: "",
    description: "",
    email: "",
    password: "",
    password_confirmation: "",
    new_password: "",
    new_password_confirmation: ""
  });

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [saveBtnState, setSaveBtnState] = useState({
    descriptionBtn: "Save Changes",
    nameEmailBtn: "Save Changes",
    passwordBtn: "Save Changes"
  });

  useEffect(() => {
    const fetchUserData = async () => {
      const response = await fetchUserProfileDetailsForEdit();
      setProfileData((prevState) => ({
        ...prevState,
        ...response.data
      }));
    }
    fetchUserData();
  }, []);

  const updateDescription = async() => {
    setSaveBtnState((prevState) => ({
      ...prevState,
      descriptionBtn: 'Saving...'
    }));
    await updateUsersDescription(profileData.description);
    setSaveBtnState((prevState) => ({
      ...prevState,
      descriptionBtn: 'Saved!'
    }));
    setTimeout(() => {
      setSaveBtnState((prevState) => ({
        ...prevState,
        descriptionBtn: 'Save Changes'
      }));
    }, 2000);
  };

  const updateNameEmail = async () => {
    setSaveBtnState((prevState) => ({
      ...prevState,
      nameEmailBtn: 'Saving...'
    }));
    await updateUserNameAndEmail(profileData.name, profileData.email);
    setSaveBtnState((prevState) => ({
      ...prevState,
      nameEmailBtn: 'Saved!'
    }));
    setTimeout(() => {
      setSaveBtnState((prevState) => ({
        ...prevState,
        nameEmailBtn: 'Save Changes'
      }));
    }, 2000);
  }

  const updatePassword = async () => {
    setSaveBtnState((prevState) => ({
      ...prevState,
      passwordBtn: `Saving...`
    }));
    await updateUserPassword({
      password: profileData.password,
      password_confirmation: profileData.password_confirmation,
      new_password: profileData.new_password,
      new_password_confirmation: profileData.new_password_confirmation
    });
    setSaveBtnState((prevState) => ({
      ...prevState,
      passwordBtn: 'Saved!'
    }));
    setTimeout(() => {
      setSaveBtnState((prevState) => ({
        ...prevState,
        passwordBtn: 'Save Changes'
      }));
    }, 2000);
    setProfileData((prevState) => ({
      ...prevState,
      password: "",
      password_confirmation: "",
      new_password: "",
      new_password_confirmation: ""
    }));
  }

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const {name, value} = e.target;
    setProfileData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    setProfileData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      try {
        const formData = new FormData();
  
        formData.append("avatar", file);
  
        const response = await axiosInstance.post(`${API.HOST}${API.USER.UPDATE_AVATAR}`, formData, {
          headers: {
            "Content-Type": "miltipart/form-data"
          }
        });

        console.log("Avatar updated successfully:", response.data.data.avatar);

        // Update state if needed
        setProfileData((prevState) => ({
          ...prevState,
          avatar: response.data.data.avatar, // Assuming the response contains the avatar URL
        }));

      } catch (error) {
        console.error("Error uploading avatar:", error);
        alert("Failed to upload avatar. Please try again.");
      }
    }
  }

  const handleChangeAvatarClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }

  return (
    <div className="text-black flex items-center justify-center p-8">
      <div className="flex flex-col md:flex-row w-full mx-auto gap-8 md:gap-0">
        {/* Profile Section */}
        <div className="flex justify-center md:w-1/3">
          <div className=" bg-[#5E5144] rounded-2xl p-8 h-fit w-80">
            <div className="flex flex-col items-center space-y-4">
              <div className="w-32 h-32 rounded-full overflow-hidden">
                <img
                  src={ profileData.avatar }
                  alt="Profile"
                  className="w-full h-full object-cover"
                />
              </div>
              <h3 className="text-lg font-medium text-gray-200 dark:text-white">
                { profileData.name }
              </h3>
              <input 
                type="file"
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleFileChange}
                ref={fileInputRef} />
              <button className="bg-black text-white px-4 py-2 rounded-lg text-sm"
                onClick={handleChangeAvatarClick}>
                Change Photo
              </button>
            </div>
          </div>
        </div>

        {/* Forms Section */}
        <div className="space-y-3 md:w-2/3">
          {/* Bio Section */}
          <div className=" bg-[#5E5144] rounded-2xl p-6">
            <div className="space-y-4">
              <h2 className="text-lg text-gray-200  dark:text-white">
                Tell us about yourself
              </h2>
              <textarea
                className="w-full h-24 bg-[#B7B7B752] text-gray-200  dark:text-white p-3 rounded-lg text-sm focus:outline-none resize-none placeholder-gray-200 dark:placeholder-white"
                placeholder="Anything in your mind..."
                value={profileData.description}
                name="description"
                onChange={ handleTextareaChange }
              />
              <div className="flex justify-center md:justify-start">
                <button 
                  className="bg-[#003465] text-white px-4 py-2 rounded-lg items-center text-sm"
                  onClick={ updateDescription }>
                  { saveBtnState.descriptionBtn }
                </button>
              </div>
            </div>
          </div>

          {/* User Settings Section */}
          <div className=" bg-[#5E5144] rounded-2xl p-6">
            <div className="space-y-4">
              <h2 className="text-lg text-gray-200 dark:text-white">
                User Settings
              </h2>

              {/* Details Section */}
              <div className="space-y-4">
                <h3 className="text-lg text-gray-200 dark:text-white">
                  Details
                </h3>
                <div className="flex flex-col md:flex-row gap-4">
                  <div className="space-y-2 w-full md:w-1/2">
                    <label className="text-sm text-gray-200 dark:text-white">
                      Name
                    </label>
                    <input
                      type="text"
                      placeholder=""
                      name="name"
                      className="w-full bg-[#B7B7B752] text-gray-200  dark:text-white p-3 rounded-lg text-sm focus:outline-none placeholder-gray-200 dark:placeholder-white"
                      value={ profileData.name }
                      onChange={ handleInputChange }
                    />
                  </div>
                  <div className="space-y-2 w-full md:w-1/2">
                    <label className="text-sm text-gray-200 dark:text-white">
                      Email
                    </label>
                    <input
                      type="text"
                      placeholder=""
                      name="email"
                      className="w-full bg-[#B7B7B752] text-gray-200  dark:text-white p-3 rounded-lg text-sm focus:outline-none placeholder-gray-200 dark:placeholder-white"
                      value={ profileData.email }
                      onChange={ handleInputChange }
                    />
                  </div>
                </div>
                <div className="flex justify-center md:justify-start">
                  <button 
                    className="bg-[#003465] text-white px-4 py-2 rounded-lg text-sm"
                    onClick={ updateNameEmail }>
                    { saveBtnState.nameEmailBtn }
                  </button>
                </div>
              </div>

              {/* Password Section */}
              <div className="space-y-4">
                <h3 className="text-lg text-gray-200 dark:text-white">
                  Password
                </h3>
                <div className="flex flex-col md:flex-row gap-4">
                  <input
                    type="password"
                    placeholder="Put your password..."
                    className="w-full md:w-1/2 bg-[#B7B7B752] text-gray-200  dark:text-white p-3 rounded-lg text-sm focus:outline-none placeholder-gray-200 dark:placeholder-white"
                    name="password"
                    onChange={ handleInputChange }
                    value={ profileData.password }
                  />
                  <input
                    type="password"
                    placeholder="Confirm password..."
                    className="w-full md:w-1/2 bg-[#B7B7B752] text-gray-200  dark:text-white p-3 rounded-lg text-sm focus:outline-none placeholder-gray-200 dark:placeholder-white"
                    name="password_confirmation"
                    onChange={ handleInputChange }
                    value={ profileData.password_confirmation }
                  />
                </div>
                <div className="flex flex-col md:flex-row gap-4">
                  <input
                    type="password"
                    placeholder="Put your new password..."
                    className="w-full md:w-1/2 bg-[#B7B7B752] text-gray-200  dark:text-white p-3 rounded-lg text-sm focus:outline-none placeholder-gray-200 dark:placeholder-white"
                    name="new_password"
                    onChange={ handleInputChange }
                    value={ profileData.new_password }
                  />
                  <input
                    type="password"
                    placeholder="Confirm new password..."
                    className="w-full md:w-1/2 bg-[#B7B7B752] text-gray-200  dark:text-white p-3 rounded-lg text-sm focus:outline-none placeholder-gray-200 dark:placeholder-white"
                    name="new_password_confirmation"
                    onChange={ handleInputChange }
                    value={ profileData.new_password_confirmation }
                  />
                </div>
                <div className="flex flex-col md:flex-row md:space-x-10 items-center">
                  <button 
                    className="bg-[#003465] text-white px-4 py-2 rounded-lg text-sm"
                    onClick={ updatePassword }>
                    { saveBtnState.passwordBtn }
                  </button>
                  <a
                    href="#"
                    className="text-gray-500 bg-white p-1.5 rounded-lg underline text-sm hover:underline mt-2 md:mt-0"
                  >
                    Forgot your password?
                  </a>
                </div>
              </div>
              <div className="flex justify-center items-center">
                <button className="bg-black py-2 px-4 text-white rounded-lg">
                  Delete Account
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
